.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/*
Remove border and background color of navigation bar
*/
.NavigationBar{
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}


/*
Border for the font awesome home icon
*/
.navbar-default{
  color: #FFFFFF;
}

.navbar-nav>li>a {
  line-height: 1.2em;
  padding-bottom: 8px;
}


/*
Underline (border below navItem)
*/
.navbar-nav>.active>a, .navbar-nav>li>a:hover{
  border-bottom: 2px solid #FFF;
}


/*
CSS for font awesome icons
*/
.fa-home, .fa-code, .fa-github, .fa-file-pdf-o {
  color: white;
  font-size: 30px;
}



/*
Background of active navitem with border
*/
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover{
  background-color: transparent;
}


/*
Navbar CSS for screens with a max-width of 767px
*/
@media (max-width: 767px){
  .navbar-default .navbar-toggle{
    border-color: white;
    background-color: white;
  }

  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover{
    background-color: white;
  }


  /*
  Underline (border below navItem)
  */
  .navbar-nav>.active>a, .navbar-nav>li>a:hover{
    border-bottom: 2px solid #FFF;
  }


  /*
  Modify navbar size and space between navitems on mobile
  */
  #root > div > nav > div > div.navbar-collapse > ul {
    width: 20%;
    margin: auto;
    border: none;
  }
  #root > div > nav > div > div.navbar-collapse > ul > li {
    padding-top: 20px;
  }
  #root > div > nav > div > div.navbar-collapse {
    min-height: 100vh;
  }
}

#not-found-title{
  padding-top: 120px;
  font-size: 30pt;
  text-decoration: underline;
}

@media (max-width: 1200px){
  #not-found-title{
    font-size: 20pt;
    padding-top: 80px;
  }
}

.navText, h1, h2{
  color: white;
}

#root{
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  font-family: sans-serif;
  min-height: 100%;
  background: url(/static/media/background.ec3f5a5a.jpg) no-repeat center center fixed;
  background-size: cover;
}

