/*
Remove border and background color of navigation bar
*/
.NavigationBar{
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}


/*
Border for the font awesome home icon
*/
.navbar-default{
  color: #FFFFFF;
}

.navbar-nav>li>a {
  line-height: 1.2em;
  padding-bottom: 8px;
}


/*
Underline (border below navItem)
*/
.navbar-nav>.active>a, .navbar-nav>li>a:hover{
  border-bottom: 2px solid #FFF;
}


/*
CSS for font awesome icons
*/
.fa-home, .fa-code, .fa-github, .fa-file-pdf-o {
  color: white;
  font-size: 30px;
}



/*
Background of active navitem with border
*/
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover{
  background-color: transparent;
}


/*
Navbar CSS for screens with a max-width of 767px
*/
@media (max-width: 767px){
  .navbar-default .navbar-toggle{
    border-color: white;
    background-color: white;
  }

  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover{
    background-color: white;
  }


  /*
  Underline (border below navItem)
  */
  .navbar-nav>.active>a, .navbar-nav>li>a:hover{
    border-bottom: 2px solid #FFF;
  }


  /*
  Modify navbar size and space between navitems on mobile
  */
  #root > div > nav > div > div.navbar-collapse > ul {
    width: 20%;
    margin: auto;
    border: none;
  }
  #root > div > nav > div > div.navbar-collapse > ul > li {
    padding-top: 20px;
  }
  #root > div > nav > div > div.navbar-collapse {
    min-height: 100vh;
  }
}
