.navText, h1, h2{
  color: white;
}

#root{
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  font-family: sans-serif;
  min-height: 100%;
  background: url(background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
